<template>
  <cs-view single-view>
    <template #content>
      <div class="organizations mt-3 mx-1">
        <div class="pb-2">
          <h3>Organizations</h3>
        </div>
        <v-card class="mx-0">
          <v-card-subtitle>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              variant="underlined"
              color="primary"
              single-line
              hide-details
            ></v-text-field>
          </v-card-subtitle>
          <v-data-table
            id="organizations-table"
            v-model="selectedOrganization"
            :row-props="organizationTableRow"
            :headers="organizationsView.headers"
            :items="organizationsView.items"
            :search="search"
          >
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template #item.userDirectory="{ item }">
              <a
                v-if="item.userDirectory"
                target="_blank"
                rel="noopener noreferrer"
                :href="item.userDirectory"
                class="text-primary"
              >
                {{ item.userDirectory }}
              </a>
              <div v-else class="textWarning">Auth0 User Directory not configured</div>
            </template>
            <template #no-data>
              <span v-if="loading" id="organizations-loading-text">Loading file...</span>
              <span v-else id="organizations-no-data-text">No data available</span>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </template>
  </cs-view>
</template>
<script>
import { CSBase } from '@complispace/cs-design-system';
import { mapGetters, mapState } from 'vuex';
import componentErrorHandler from '@/helpers/componentErrorHandler';

export default {
  name: 'OrganizationsPage',

  components: {},

  extends: CSBase,

  data() {
    return {
      search: '',
      selectedOrganization: []
    };
  },

  computed: {
    ...mapGetters({
      organizationsView: 'organizations/organizationsView'
    }),

    ...mapState({
      organizations: (state) => state.organizations.organizations
    })
  },

  async mounted() {
    try {
      this.setLoading(true);
      await this.$store.dispatch('organizations/fetchOrganizations');
      this.clearLoading();
    } catch (e) {
      componentErrorHandler(this, e, undefined, false);
    }
  },

  methods: {
    organizationTableRow() {
      return {
        class: 'organizations-table-row'
      };
    }
  }
};
</script>
<style scoped>
.textWarning {
  font-style: italic;
  color: grey;
}
</style>
